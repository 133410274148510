import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const ArmChairStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container'>
                <h1 className='text-center'>Metal Koltuk İskeletlerinde Mükemmeliyet</h1>
                <p className='ps-3 pe-3 mt-4'>Nasran Metal, metal koltuk iskeletleri üretiminde sektörde öncüdür ve müşterilere yüksek kaliteli, konforlu ve estetik çözümler sunma konusunda kararlıdır. Güçlü endüstri deneyimi ve teknolojik yenilikçilikle donatılmış olan şirketimiz, metal koltuk iskeletleri konusunda güvenilir bir iş ortağıdır.</p>
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/koltuk_iskeleti.jpg')} width={'450px'} alt='koltuk_isk'/>
                </div>
                <h3>Üstün Malzeme ve Mükemmel İşçilik</h3>
                <p>Nasran Metal, metal koltuk iskeletlerinde kullanılan malzemeleri özenle seçer ve en üst düzeyde işler. Ürünlerimiz, yüksek mukavemetli metallerden üretilir ve uzun ömürlü kullanım sağlar. İşçilik konusundaki hassasiyetimiz, her bir ürünün detaylı bir kalite kontrol sürecinden geçmesini sağlar.</p>
                <h3 className='mt-5'>Ergonomik Tasarım ve Konforlu Kullanım</h3>
                <p>Metal koltuk iskeletlerimiz, sadece dayanıklı olmakla kalmaz, aynı zamanda ergonomik tasarım prensiplerine uygun olarak geliştirilir. Konforlu oturuş pozisyonları ve destekleyici yapılar, müşterilerimize mükemmel bir oturma deneyimi sunar. Tasarım çeşitliliğimiz, farklı tarzlara ve mekanlara uygun seçenekler sunar.</p>
                <h3 className='mt-5'>Kişiselleştirilmiş Çözümler ve Proje İşbirliği</h3>
                <p>Müşteri memnuniyetini ön planda tutan Nasran Metal, özel projeler ve talepler için esnek çözümler sunar. Profesyonel ekibimiz, müşterilerimizle yakın işbirliği içinde çalışarak, özel gereksinimleri anlar ve en iyi çözümleri sağlar. Her aşamada müşterilerimizin beklentilerini karşılamak için çaba gösteririz.</p>
                <h3 className='mt-5'>Çevre Dostu Üretim ve Sürdürülebilirlik</h3>
                <p className='mb-5'>Nasran Metal, sürdürülebilirlik ilkelerine bağlı olarak çevre dostu üretim süreçlerini benimser. Atık yönetimi, enerji verimliliği ve çevre bilinci konularında sürekli iyileştirmeler yaparak, çevresel etkimizi en aza indiririz.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default ArmChairStructure;