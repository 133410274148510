import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const LibraryStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container'>
                <h1 className='text-center'>Kitaplık İskeletlerinde Estetik ve Dayanıklılığın Sembolü</h1>
                <div className='d-flex w-100 justify-content-center mt-5 mb-5'>
                    <img src={require('../../assets/kitaplik_iskelet.jpeg')} width={'450px'} height={400} alt='kitaplik_isk'/>
                </div>
                <p className='text-center'>Nasran Metal, kurumsal müşterilere özel olarak tasarlanmış kitaplık iskeletleri konusunda güvenilir bir çözüm sunmaktadır.</p>
                <h3 className='mt-5'>Güçlü Malzeme ve Sağlam İşçilik</h3>
                <p>Kitaplık iskeletlerimiz, sektördeki en yüksek kalite standartlarını karşılayan malzemeler kullanılarak üretilir. Metalin dayanıklılığına ek olarak, özel tasarım ve dikkatli işçilik sayesinde kitaplık iskeletlerimiz uzun ömürlü ve sağlamdır. Her bir detay, güçlü temellerle desteklenerek kitap koleksiyonlarını güvenle tutar.</p>
                <h3 className='mt-5'>Estetik Tasarım ve Çeşitlilik</h3>
                <p>Nasran Metal, kitaplık iskeletlerini tasarlarken estetik ve fonksiyonelliği bir araya getirir. Geniş tasarım seçenekleri arasında modern ve klasik tarzlar bulunur, böylece işletmeler kendi dekorasyon anlayışlarına uygun bir kitaplık iskeleti seçebilirler. Estetik detaylar, kitaplık alanına zarafet katmak için özenle düşünülerek eklenir.</p>
                <h3 className='mt-5'>Özel Ölçüler ve Proje İşbirliği</h3>
                <p>Müşteri ihtiyaçlarına uygun özel ölçüler ve tasarımlar, Nasran Metal'in kitaplık iskeletleri üretiminde öne çıkan özelliklerindendir. İşletmelerin özel projeleri ve mekan gereksinimleri için esnek çözümler sunarak, kitaplık iskeletlerini mekanın ihtiyaçlarına mükemmel bir şekilde entegre etmek amacındayız.</p>
                <h3 className='mt-5'>Sürdürülebilirlik ve Çevre Dostu Üretim</h3>
                <p className='mb-5'>Nasran Metal, çevre dostu üretim süreçleri ve sürdürülebilir malzeme kullanımı konusundaki taahhüdüyle öne çıkar. Atık yönetimi, enerji verimliliği ve geri dönüşüm konularında sürekli iyileştirmeler yaparak, çevresel etkileri en aza indirmeye odaklanır.</p>

            </div>
            <Footer />
        </div>
     );
}
 
export default LibraryStructure;