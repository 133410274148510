import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const Factory = () => {
    return ( 
        <div className="factoryContainer">
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container mb-5'>
                <h1 className='mb-3'>Üretim Tesisimiz</h1>
                <p>Nasran Metal, Düzce'deki <b style={{color:'#004469'}}>1200 m2 kapalı</b> alana ve <b style={{color:'#004469'}}>3000 m2 açık alana </b>sahip toplam <b style={{color:'#004469'}}>4200 m2'lik geniş üretim alanında </b>faaliyet gösteren bir metal iskelet üreticisidir. 2002 yılından bu yana, üstün teknolojik altyapısı ve uzman kadrosuyla metal iskelet sektöründe kaliteli hizmet sunmaktadır. Firmamız, müşteri memnuniyetini esas alan bir şirket politikası benimseyerek, metal iskelet alanındaki çeşitli ihtiyaçlara yönelik inovatif çözümler sunma konusunda kendini adamıştır.</p>
                <div className='development_field_images'>
                    <img src={require('../../assets/carousel_image_1.jpeg')} alt='i1'/>
                    <img src={require('../../assets/carousel_image_2.jpeg')} alt='i2'/>
                    <img src={require('../../assets/carousel_image_3.jpeg')} alt='i3'/>
                    <img src={require('../../assets/carousel_image_4.jpeg')} alt='i4'/>
                    <img src={require('../../assets/carousel_image_5.jpeg')} alt='i5'/>
                    <img src={require('../../assets/c2.jpg')} alt='c22'/>
                </div>
                <p className='mt-4 mb-5'>Geniş üretim alanımız, modern teknolojiyle donatılmış tesisimiz ve uzman personelimiz sayesinde, müşterilerimize yüksek kaliteli metal iskelet ürünleri sağlamak için en iyi uygulamaları birleştiriyoruz. Her bir projeye özel çözümler üretme yeteneğimiz, sektördeki gelişmeleri yakından takip ederek sürekli olarak güncellenen teknik bilgi birikimimizle desteklenmektedir.</p>
                <p><b style={{color:'#004469'}}>Nasran Metal</b> olarak, sadece ürünlerimizin kalitesine odaklanmakla kalmayıp, aynı zamanda müşterilerimizle uzun vadeli iş ilişkileri kurarak güvenilir bir ortak olmayı amaçlıyoruz. Şirketimizin misyonu, müşterilerimize özel ihtiyaçlarına uygun, dayanıklı ve estetik metal iskelet çözümleri sunarak beklentilerini aşmaktır.</p>
                <p>Kalite standartlarımızı koruma ve geliştirme taahhüdümüz doğrultusunda, sürekli iyileştirme prensiplerine bağlı kalarak sektördeki lider konumumuzu sürdürmeye devam ediyoruz. <b style={{color:'#004469'}}>Nasran Metal</b> olarak, metal iskelet alanındaki uzmanlığımızla, müşteri memnuniyetini en üst düzeye çıkaran çözümler sunmaya devam edeceğiz.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default Factory;