import './About.css';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const About = () => {
    return ( 
        <div className="aboutContainer">
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
            </div>
            <div className='container mb-5'>
                <h1 className='mb-4'>Hakkımızda</h1>
                <p className='mb-5'><b>Nasran Metal</b> olarak hızla değişen ihtiyaçlara anında ve etkin bir şekilde yanıt verme kabiliyetimizle, uzman teknik ekibimizle birlikte zaman yönetimini öncelikli tutarak hizmet sunmaktayız. Ar-Ge çalışmalarımız, sektör analizleri ve 20 yıllık tecrübemizle birleştirilerek, sizlerin taleplerini karşılamak için özelleştirilmiş çözümler üretiyoruz. Standart ürün sınırlarını aşarak, özel Ar-Ge ve üretim projeleriyle müşteri memnuniyetini maksimum seviyeye çıkarmayı hedefliyoruz.</p>
                <p className='mb-5'>Teknolojik gelişmeleri yakından takip eden Ar-Ge çalışmalarımız ve sektör analizleri, 20 yılı aşkın süredir kazandığımız tecrübeyle bir araya gelerek, siz değerli müşterilerimizin beklentilerini karşılamak üzere özelleştirilmiş çözümler sunmaktayız. Standart ürün sınırlarını zorlayarak, özel Ar-Ge ve üretim projeleriyle müşteri memnuniyetini maksimum seviyeye çıkarma vizyonuyla çalışıyoruz.</p>
                <p className='mb-5'>Firmamızın sahip olduğu 20 yıllık birikimle, sektörde öncü konumumuzu daha da güçlendirmek ve müşterilerimize en yüksek kalitede ürün ve hizmetleri sunmak için sürekli olarak kendimizi geliştiriyoruz. Yüksek standartlarımızı korumak ve aşmak adına kalite politikamıza sıkı sıkıya bağlı kalarak, sürdürülebilir bir başarı elde etmeyi amaçlıyoruz.</p>
                <p>Nasran Metal olarak, müşterilerimizle kurduğumuz güven ilişkisi üzerine inşa ettiğimiz işbirliğimizle, sektördeki liderliğimizi sürdürmeyi hedefliyoruz. Her bir proje ve talep üzerine özel bir yaklaşım benimseyerek, müşteri memnuniyetini en üst düzeye çıkaran çözümler üretmeye devam edeceğiz.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default About;