import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    return ( 
        <div className="contactContainer">
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container mb-5'>
                <h1 className='mb-3'>İletişim</h1>
                <p>Her türlü soru, görüş veya talepleriniz için sizinle iletişim kurmayı bekliyoruz. <b style={{color:'#004469'}}>Nasran Metal</b> olarak, müşteri memnuniyetini en üst seviyede tutma amacıyla buradayız. Profesyonel ve deneyimli ekibimiz, sizinle işbirliği yaparak ihtiyaçlarınıza özel çözümler üretmeye hazır.</p>
                <p className='mb-4 mt-5 fw-bold'>İletişim Bilgilerimiz</p>
                <p>
                    <FontAwesomeIcon icon={faLocationDot} className='me-2'/>
                    Üçyol Köyü, İstiklal Mevkii No: 45 Küme Evler, Merkez / Düzce
                </p>
                <p>
                    <FontAwesomeIcon icon={faEnvelope} className='me-2'/>
                    info@nasranmetal.com.tr
                </p>
                <p>
                    <FontAwesomeIcon icon={faPhone} className='me-2'/>
                    +90 533 050 81 83
                </p>
            </div>
            <Footer />
        </div>
     );
}
 
export default Contact;