import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

const ChairStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <h1 className="text-center">Metal Sandalye İskeletlerinde Kalite ve Estetik</h1>
            <div className="container">
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/sandalye_iskelet.jpeg')} alt="sandalye_isk"/>
                </div>
                <p>Nasran Metal, sektörde lider olarak metal sandalye iskeletleri üretiminde uzmanlaşmış bir şirkettir. Yılların getirdiği deneyim ve yenilikçi yaklaşımıyla, müşterilere dayanıklı, estetik ve fonksiyonel çözümler sunmaktayız.</p>
                <h3 className="mt-5">Kaliteli Malzeme ve Güvenilir Üretim Süreci</h3>
                <p>Ürünlerimizde kullanılan malzemeler, en yüksek kalite standartlarına uygun olarak seçilmekte ve işlenmektedir. Metal sandalye iskeletleri, dayanıklılık ve uzun ömür garantisiyle üretilir. Üretim sürecimiz, endüstri standartlarını aşan kalite kontrol önlemleri içermekte ve ürünlerimizin her aşamasında titizlikle incelenmektedir.</p>
                <h3 className="mt-5">Estetik Tasarım ve Çeşitlilik</h3>
                <p>Nasran Metal, sadece dayanıklılıkla değil, aynı zamanda estetikle de öne çıkar. Ürün yelpazemiz, modern tasarım prensipleriyle uyumlu olarak geliştirilmiş ve çeşitli tarzlara hitap edecek şekilde genişletilmiştir. Müşterilerimiz, mekanlarını kişiselleştirmek ve marka imajlarını güçlendirmek için çeşitli renk ve desen seçeneklerinden faydalanabilirler.</p>
                <h3 className="mt-5">Özel Üretim ve Müşteri Memnuniyeti</h3>
                <p>Nasran Metal olarak, müşteri memnuniyetine büyük önem veriyoruz. Özel talepler ve proje ihtiyaçları için esnek bir yaklaşım benimseyerek, müşterilerimizin beklentilerini karşılamak üzere özel üretim çözümleri sunuyoruz. Profesyonel ekibimiz, müşterilerimizle işbirliği yaparak onların benzersiz gereksinimlerini anlamakta ve en iyi çözümleri sunmaktadır.</p>
                <h3 className="mt-5">Çevre Dostu Üretim</h3>
                <p>Nasran Metal, sürdürülebilirlik ilkesine bağlı olarak çevre dostu üretim süreçlerini benimsemiştir. Atık azaltma, enerji verimliliği ve geri dönüşüm konularında sürekli çaba göstererek, çevresel etkimizi en aza indiriyoruz.</p>
                <br />
                <p className="mb-5">Kalite, estetik ve müşteri memnuniyeti konularında standartları belirleyen Nasran Metal, metal sandalye iskeletleri alanında güvenilir bir iş ortağınızdır. İhtiyaçlarınıza özel çözümler ve dayanıklılığın birleşimi için bize ulaşın.</p>

            </div>
            <Footer />
        </div>
     );
}
 
export default ChairStructure;