import './Home.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLocationArrow, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    return ( 
        <div className='homePageContainer'>
            <Navbar />
            <div className=''>
                <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
                <div className='homeCarouselContainer'>
                    <div id="carouselExample" className="carousel slide">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={require('../../assets/c3.jpg')} className="d-block w-100" alt="c3" />
                            </div>
                            <div className="carousel-item">
                                <img src={require('../../assets/c2.jpg')} className="d-block w-100" alt="c2" />
                            </div>
                            <div className="carousel-item">
                                <img src={require('../../assets/c1.jpg')} className="d-block w-100" alt="c1" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div className='container mt-5'>
                    <h2 className='fw-bold text-center'><span style={{color:'#015d8e'}}>Kalite</span> Anlayışımız</h2>
                    <p className='text-center text-secondary'>Hızla evrilen ihtiyaçlar ve beklentilere duyarlı bir şekilde, teknik ekibimizle birlikte zamanın önemini gözeterek çözüm üretiyoruz. Ar-Ge çalışmalarımızda, müşteri taleplerini sektör analiziyle birleştirip, neredeyse 30 yıl süren deneyimimizle birleştiriyoruz. Müşteri odaklı yaklaşımımızla, standartların ötesine geçip, firma özelinde Ar-Ge ve üretim gerçekleştiriyoruz.</p>
                    <div className='quality_items'>
                        <div className='quality_items_element'>
                            <FontAwesomeIcon icon={faCheck} size={'2x'} className='mb-2'/>
                            <h4>A Kalite</h4>
                            <p className='text-center'>Nasran Metal imzasını taşıyan her ürün, titiz bir kontrol sürecinden geçer ve üst düzey kalite standartlarına uygun olarak üretilir.</p>
                        </div>
                        <div className='quality_items_element'>
                            <FontAwesomeIcon icon={faThumbsUp} size='2x' className='mb-2'/>
                            <h4>Müşteri Memnuniyeti</h4>
                            <p className='text-center'>Şirket politikamızın temel öncelikleri arasında müşteri memnuniyetine yüksek bir vurgu yapmaktayız. <br/> Sizin için varız.</p>
                        </div>
                        <div className='quality_items_element'>
                            <FontAwesomeIcon icon={faLocationArrow} size='2x' className='mb-2'/>
                            <h4>Hedeflerimiz</h4>
                            <p className='text-center'>Nasran Metal olarak hedefimiz, mevcut standartlarımızı sürdürmek ve müşterilerimizin <u>1 numaralı tercihi</u> olmaya devam etmektir.</p>
                        </div>
                    </div>
                    <div className='primary_products'>
                        <h2 className='fw-bold text-center pt-3'>Öne Çıkan <span style={{color:'#015d8e'}}>Ürünlerimiz</span></h2>
                        <div className='primary_products_list_container'>
                            <div className='list_item'>
                                <img src={require('../../assets/metal-masa-ayagi.jpeg')} alt='metal_masa'/>
                                <b className='mt-2 ms-2'>Metal Masa İskelet</b>
                                <a href='/metal-masa-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/sandalye_iskelet.jpeg')} alt='sandalye_isk'/>
                                <b className='mt-2 ms-2'>Metal Sandalye İskelet</b>
                                <a href='/metal-sandalye-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/koltuk_iskeleti.jpg')} alt='koltuk_isk'/>
                                <b className='mt-2 ms-2'>Metal Koltuk İskelet</b>
                                <a href='metal-koltuk-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/karyola_iskelet.webp')} alt='karyola_isk'/>
                                <b className='mt-2 ms-2'>Metal Karyola İskelet</b>
                                <a href='metal-karyola-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/kitaplik_iskelet.jpeg')} alt='kitaplik_isk'/>
                                <b className='mt-2 ms-2'>Kitaplık Grubu</b>
                                <a href='kitaplik-grubu' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/3_lu_iskelet.jpeg')} alt='3_lu_isk'/>
                                <b className='mt-2 ms-2'>Metal 3'lü İskelet</b>
                                <a href='metal-3lu-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/dolap_iskelet.webp')} alt='dolap_isk'/>
                                <b className='mt-2 ms-2'>Metal Dolap İskelet</b>
                                <a href='metal-dolap-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                            <div className='list_item'>
                                <img src={require('../../assets/metal_raf.jpeg')} alt='metal_rf'/>
                                <b className='mt-2 ms-2'>Metal Raf İskelet</b>
                                <a href='metal-raf-iskelet' className='btn btn-dark mt-3'>İncele</a>
                            </div>
                        </div>
                    </div>
                    <div className='about_us'>
                        <div className='about_us_text_field'>
                            <h3 style={{color:'#005D8E', fontWeight:'bolder'}}>Hakkımızda</h3>
                            <p>Hızla değişen ihtiyaçlara anında ve etkin bir şekilde yanıt verme kabiliyetimizle, uzman teknik ekibimizle birlikte zaman yönetimini öncelikli tutarak hizmet sunmaktayız. Ar-Ge çalışmalarımız, sektör analizleri ve 20 yıllık tecrübemizle birleştirilerek, sizlerin taleplerini karşılamak için <u>özelleştirilmiş</u> çözümler üretiyoruz. Standart ürün sınırlarını aşarak, özel Ar-Ge ve üretim projeleriyle müşteri memnuniyetini maksimum seviyeye çıkarmayı hedefliyoruz.</p>
                        </div>
                        <div className='about_us_img_field'>
                            <img src={require('../../assets/c3.jpg')} alt='c3'/>
                        </div>
                    </div>
                    <div className='development_field'>
                        <h2 className='text-center fw-bold'>Üretim <span style={{color:'#005D8E'}}>Tesisimiz</span></h2>
                        <p className='text-muted mt-4 text-center'>Düzce'de bulunan <b style={{color:'#005D8E'}}>1200 m2 kapalı </b>ve <b style={{color:'#005D8E'}}>3000 m2 açık</b> olmak üzere toplam <b style={{color:'#005D8E'}}>4200 m2</b> geniş üretim alanımızda, üstün teknolojik altyapımız ve işinin ehli kadromuz ile beraber 2002 senesinden beri metal iskelet sektöründe hizmet vermekteyiz. Müşteri memnuniyetini en ön planda tutan şirket politikamız ile siz değerli müşterilerimizin metal iskelet grubunda her çeşit ihtiyacına inovatif çözümler sunmaktayız.</p>
                        <div className='development_field_images'>
                            <img src={require('../../assets/carousel_image_1.jpeg')} alt='i1'/>
                            <img src={require('../../assets/carousel_image_2.jpeg')} alt='i2'/>
                            <img src={require('../../assets/carousel_image_3.jpeg')} alt='i3'/>
                            <img src={require('../../assets/carousel_image_4.jpeg')} alt='i4'/>
                            <img src={require('../../assets/carousel_image_5.jpeg')} alt='i5'/>
                            <img src={require('../../assets/c2.jpg')} alt='c22'/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
     );
}
 
export default Home;