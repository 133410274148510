import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return ( 
        <div className='footerContainer'>
            <div className='homeNasranLogoContainer'>
                <a href='/'>
                    <img src={require('../../assets/logo-no-background.png')} id='footer_nasran_logo' alt='nasran_logo'/>
                </a>
            </div>
            <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px', fontSize: '12px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
            
            <div className='footerRoutesContainer'>
                <div className='d-flex flex-column gap-3'>
                    <h4 className='mb-1'>Sayfalar</h4>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <a href='tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
                <div className='d-flex flex-column gap-3'>
                    <h4 className='mb-1'>Ürünler</h4>
                    <a href='/metal-masa-iskelet'>Metal Masa İskelet</a>
                    <a href='/metal-sandalye-iskelet'>Metal Sandalye İskelet</a>
                    <a href='/metal-koltuk-iskelet'>Metal Koltuk İskelet</a>
                    <a href='/metal-karyola-iskelet'>Metal Karyola İskelet</a>
                    <a href='/kitaplik-grubu'>Kitaplık Grubu</a>
                    <a href='/metal-3lu-iskelet'>Metal 3'lü İskelet</a>
                    <a href='/metal-dolap-iskelet'>Metal Dolap İskelet</a>
                    <a href='/metal-raf-iskelet'>Metal Raf İskelet</a>
                </div>
                <div className='d-flex flex-column gap-3'>
                    <h4 className='mb-1'>Adres</h4>
                    <p className='mb-0'>Üçyol Köyü, İstiklal  Mevkii No: 45</p>
                    <p>Küme Evler</p>
                    <p>Merkez / Düzce</p>
                </div>
                <div className='d-flex flex-column gap-3'>
                    <h4 className='mb-1'>İletişim</h4>
                    <a href='mailto:info@nasranmetal.com.tr'>
                        <FontAwesomeIcon icon={faEnvelope} className='me-2'/>
                        info@nasranmetal.com.tr
                    </a>
                    <a href='tel:+905330508183'>
                        <FontAwesomeIcon icon={faPhone} className='me-2'/>
                        +90 533 050 81 83
                    </a>
                </div>
            </div>
            
            <div className='footerTagContainer'>
                <p className='mb-0 p-1 text-center'>Designed & Developed by <a className='ms-1 text-white' target='_blank' rel='noreferrer' href='https://www.linkedin.com/in/caner-eren-%C3%A7al%C4%B1%C5%9Fkan-7a16a61a9/'>CALSKAN</a></p>
            </div>
        </div>
     );
}
 
export default Footer;