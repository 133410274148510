import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const BedSteadStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container'>
                <h1 className='text-center'>Güçlü Karyola İskeletleri</h1>
                <p className='ps-3 pe-3 mt-3'>Nasran Metal, kurumsal müşterilere özel olarak tasarlanmış metal karyola iskeletleri üretiminde uzmanlaşmıştır. Metal iskeletlerimiz, işletmelerin yatak ürünleri segmentinde estetik, dayanıklılık ve özelleştirme konularında beklentilerini karşılamak üzere tasarlanmıştır.</p>
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/karyola_iskelet.webp')} width={'450px'} height={400} alt='karyola_isk'/>
                </div>
                <h3 className='mt-5'>Dayanıklılık ve Güvenilirlikte Sınıfının Lideri</h3>
                <p>Nasran Metal, kurumsal müşterilere yönelik metal karyola iskeletleri üretiminde sektör lideridir. Kullandığımız yüksek kaliteli metaller ve güçlü tasarımlar, uzun ömürlü kullanım ve güvenilir performans sağlar. İş ortaklarımızın müşterilerine en iyi kalitede ürünleri sunmalarına yardımcı olmak için çaba gösteriyoruz.</p>
                <h3 className='mt-5'>Estetik ve Fonksiyonellikte Fark Yaratın</h3>
                <p>Metal karyola iskeletlerimiz, işletmelerin marka kimliğine ve tasarım estetiğine uyum sağlamak üzere özel olarak geliştirilmiştir. Geniş tasarım çeşitliliğimiz, farklı otel, konaklama ve mobilya sektörlerine hitap etmek üzere özelleştirilebilir seçenekler sunar. Hem estetik hem de fonksiyonellik konusunda beklentileri aşmak için sürekli olarak çözümler üretiyoruz.</p>
                <h3 className='mt-5'>Özel Projeler ve Kişiselleştirilmiş Çözümler</h3>
                <p>Nasran Metal, kurumsal müşterilerinin özel projelerine ve ihtiyaçlarına odaklanarak kişiselleştirilmiş çözümler sunar. İhtiyacınıza uygun özel tasarımlar ve projelerle işbirliği yaparak, işletmenizin öne çıkmasını sağlamak için yanınızdayız. Her adımda güvenilir ve esnek bir iş ortağı olarak hizmet veriyoruz.</p>
                <h3 className='mt-5'>Çevresel Sorumluluk ve Sürdürülebilirlik</h3>
                <p>Nasran Metal, çevre dostu üretim süreçleri ile sürdürülebilirliği ön planda tutar. Atık yönetimi, enerji verimliliği ve çevresel etkileri en aza indirme konusundaki taahhüdümüz, iş ortaklarımızla birlikte sürdürülebilir bir geleceğe katkıda bulunmayı amaçlar.</p>
                <br />
                <p className='mb-5'>Nasran Metal, metal karyola iskeletleri konusunda güçlü ve sağlam bir iş ortağıdır. İşletmenizin ihtiyaçlarına uygun özel çözümler ve yüksek kalite standartları ile işbirliği yapmak için sizi bekliyoruz.</p>

            </div>
            <Footer />
        </div>
     );
}
 
export default BedSteadStructure;