import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import DeskStructure from './pages/desk-structure/DeskStructure';
import ChairStructure from './pages/chair-structure/ChairStructure';
import ArmChairStructure from './pages/armchair-structure/ArmchairStructure';
import BedSteadStructure from './pages/bedstead-structure/BedSteadStructure';
import LibraryStructure from './pages/library-structure/LibraryStructure';
import ThreeStructure from './pages/three-structure/ThreeStructure';
import ClosetStructure from './pages/closet-structure/ClosetStructure';
import ShelfStructure from './pages/shelf-structure/ShelfStructure';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Factory from './pages/factory/Factory';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: '/metal-masa-iskelet',
    element: <DeskStructure />
  },

  {
    path: '/metal-sandalye-iskelet',
    element: <ChairStructure />
  },

  {
    path: '/metal-koltuk-iskelet',
    element: <ArmChairStructure />
  },

  {
    path: '/metal-karyola-iskelet',
    element: <BedSteadStructure />
  },

  {
    path: '/kitaplik-grubu',
    element: <LibraryStructure />
  },

  {
    path: '/metal-3lu-iskelet',
    element: <ThreeStructure />
  },

  {
    path: '/metal-dolap-iskelet',
    element: <ClosetStructure />
  },

  {
    path: '/metal-raf-iskelet',
    element: <ShelfStructure />
  },

  {
    path: '/hakkimizda',
    element: <About />
  },

  {
    path: 'tesisimiz',
    element: <Factory />
  },

  {
    path: 'iletisim',
    element: <Contact />
  }

]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);