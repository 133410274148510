import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const ThreeStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container'>
                <h1 className='text-center'>Sağlam İskelet, Sağlam Mekanizma</h1>
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/3_lu_iskelet.jpeg')} width={'450px'} height={400} alt='3_lu_isk'/>
                </div>
                <p className='mb-5 ps-3 pe-3'>Tüm iskeletlerimiz ve mekanizmaları, sektördeki en yüksek kalite standartlarını karşılayan malzemeler kullanılarak üretilir. Metalin dayanıklılığına ek olarak, özel tasarım ve dikkatli işçilik sayesinde iskeletlerimiz ve mekanizmaları uzun ömürlü ve sağlamdır. Her bir detay, güçlü temellerle desteklenir.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default ThreeStructure;