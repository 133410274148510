import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const ClosetStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container'>
                <h1 className='text-center'>Fonksiyonel ve Dayanıklı Metal Dolap İskeletleri</h1>
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/dolap_iskelet.webp')} width={'450px'} height={400} alt='dolap_isk'/>
                </div>
                <p className='ps-3 pe-3 mt-3'>Metal dolap iskeletleri, en dayanıklı ve kaliteli metallerden üretilir. Nasran Metal, sektördeki en güvenilir malzemeleri seçerek iş ortaklarına uzun ömürlü ve sağlam ürünler sunar. Her bir dolap iskeleti, güçlü yapıları ve özenle işlenmiş detayları ile öne çıkar.</p>
                <h3 className='mt-5'>Özel Ölçüler ve Projeye Özel Çözümler</h3>
                <p className='mb-5 ps-2 pe-2'>Nasran Metal, müşteri ihtiyaçlarına uygun özel ölçüler ve tasarımlar sunarak metal dolap iskeletlerini kişiselleştirir. İşletmelerin özel projeleri ve depolama ihtiyaçları için esnek çözümler üreterek, depolama alanlarını en verimli şekilde kullanmalarına yardımcı olur.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default ClosetStructure;