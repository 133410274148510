import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import './DeskStructure.css';
const DeskStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container structure-container'>
                <h1 className='text-center'>Metal Masa İskeletlerinde Güvenilir Çözümler</h1>
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/metal-masa-ayagi.jpeg')} alt='metal_masa_ayk'/>
                </div>
                <p>Her alanda estetik ve dayanıklılık önemlidir, ancak özellikle ofisler, restoranlar, oteller ve evlerde kullanılan masa iskeletleri için bu özellikler kritik hale gelir. Nasran Metal olarak, metal masa iskeletleri konusunda uzman ekibimizle müşterilerimize birinci sınıf kalitede ürünler sunarız.</p>
                <h3 className='mt-5'>Kalite ve Dayanıklılık</h3>
                <p>Üretim sürecimizde kaliteli malzemeler ve modern üretim teknikleri kullanarak, metal masa iskeletlerimizde en yüksek standartları sağlıyoruz. Her bir ürünümüz, uzun ömürlü kullanım için özenle tasarlanmış ve üretilmiştir. İhtiyaca göre farklı malzeme seçenekleri ve kaplama çeşitleri ile müşterilerimize geniş bir ürün yelpazesi sunuyoruz.</p>
                <p>Her bir metal masa iskeletimiz, uzun ömürlü kullanımı garanti eden özel bir tasarım ve üretim sürecinden geçer. Ürünlerimiz, dayanıklılık ve estetik arasında mükemmel bir denge sağlamak için tasarlanır. Her aşamada kalite kontrolünden geçen ürünlerimiz, müşterilerimize yüksek performans ve güvenilirlik sunar.</p>
                <h3 className='mt-5'>Özel Tasarım Çözümleri</h3>
                <p>Nasran Metal, müşterilerin özel ihtiyaçlarına yönelik çözümler sunma konusunda uzmanlaşmıştır. Mimari tarzınıza ve mekanınıza uygun olarak özel tasarlanmış masa iskeletleri ile mekanlarınıza özgü bir dokunuş katıyoruz. Tasarım ve fonksiyonelliği bir araya getirerek, müşterilerimizin beklentilerini aşan ürünler sunuyoruz.</p>
                <h3 className='mt-5'>Çevre Dostu Üretim</h3>
                <p>Nasran Metal olarak, sürdürülebilir üretim ilkelerine bağlı kalıyor ve çevre dostu malzemeleri tercih ediyoruz. Atık azaltma ve enerji verimliliği konularında sürekli iyileştirmeler yaparak, çevreye duyarlı bir üretim sürecini benimsiyoruz.</p>
                <h3 className='mt-5'>Müşteri Memnuniyeti Odaklı Hizmet</h3>
                <p className='mb-5'>Nasran Metal olarak, müşteri memnuniyetini en üst düzeye çıkarmak için yoğun bir çaba harcıyoruz. Müşterilerimizle başarılı bir işbirliği kurmayı, ihtiyaçlarını anlamayı ve en etkili çözümleri sunmayı işimizin temel prensipleri olarak benimsemekteyiz. Her bir müşterimiz, özel taleplerine ve beklentilerine uygun çözümler bulma konusundaki kararlılığımızın bir yansımasıdır.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default DeskStructure;