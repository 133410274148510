import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';

const ShelfStructure = () => {
    return ( 
        <div>
            <Navbar />
            <div className='totalNavbarContainer'>
                    <a href='/'>Anasayfa</a>
                    <a href='/hakkimizda'>Hakkımızda</a>
                    <div>
                        <div className='homeNasranLogoContainer'>
                            <a href='/'>
                                <img src={require('../../assets/logo-no-background.png')} id='home_nasran_logo' alt='nasran_logo'/>
                            </a>
                        </div>
                        <p className='text-center mt-3 mb-5' style={{letterSpacing: '3px'}}>20 Yıllık Tecrübe ile Lider İş Ortağınız</p>
                    </div>

                    <a href='/tesisimiz'>Tesisimiz</a>
                    <a href='/iletisim'>İletişim</a>
                </div>
            <div className='container'>
                <h1>Metal Raf İskeletleri</h1>
                <p className='mt-2 ps-0 pe-3'>Nasran Metal, kurumsal müşterilere yönelik olarak tasarlanmış metal raf iskeletleri ile depolama çözümlerine öncülük etmektedir. İşletmelerin depolama ve düzenleme ihtiyaçlarına hitap eden metal raf iskeletlerimiz, dayanıklılık, esneklik ve estetik tasarım özellikleriyle öne çıkar.</p>
                <div className='d-flex w-100 justify-content-center'>
                    <img src={require('../../assets/metal_raf.jpeg')} width={'450px'} height={400} alt='metal_rf'/>
                </div>
                <h3 className='mt-4'>Dayanıklılık ve Yüksek Taşıma Kapasitesi</h3>
                <p className='mb-5'>Metal raf iskeletlerimiz, ağır yükleri güvenle taşıma kapasitesiyle dikkat çeker. Her bir raf iskeleti, ağırlık dağılımı ve dayanıklılık açısından titizlikle test edilir, böylece işletmeler depolama alanlarında güvenilir performans elde ederler.</p>
            </div>
            <Footer />
        </div>
     );
}
 
export default ShelfStructure;